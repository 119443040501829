
 
  /* Slick Slider Pagination Styles */
  .slick-dots {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .slick-dots li {
    display: inline-block;
    padding: 0;
    margin: 0 5px
  }
  
  .slick-dots button {
    font-size: 0;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    border: none;
    border-radius: 10%;
    cursor: pointer;
  }
  
  .slick-dots button.slick-active {
    background-color: #fff; 
  }