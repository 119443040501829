


.Quote {
  /* Styles for the quote container element */
  background-color: #f5f5f5;
  padding: 20px;
}

.Quote h2 {
  /* Styles for the quote heading */
  text-align: center;
  margin-bottom: 15px;
}
