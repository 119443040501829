/* Services Container */
.Services {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  align-items: center; /* Center the cards horizontally */
  padding: 20px;
  text-align: center;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

/* Card Styles */
.service-card {
  background-color: #444; /* Card background */
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0; /* Margin only on top and bottom to stack vertically */
  width: 100%; /* Full width of the container */
  max-width: 600px; /* Prevent cards from becoming too wide */
  box-sizing: border-box;
  transition: transform 0.3s ease;
  color: #f0f0f0; /* Light text color */
  text-align: center;
  height: auto;
}

/* Card Hover Effect */
.service-card:hover {
  transform: translateY(-5px); /* Hover effect */
}

/* Card Image */
.service-card img {
  width: 100%;
  height: 150px; /* Fixed height for images */
  object-fit: cover;
  border-radius: 10px;
}

/* Card Title */
.service-card h3 {
  color: #f0f0f0;
  font-size: 1.5rem;
  margin-top: 10px;
}

/* Card Description */
.service-card p {
  font-size: 1rem;
  color: #f0f0f0;
  margin-top: 5px;
}

/* Card Button */
.service-card a {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.service-card a:hover {
  background-color: #0056b3;
}

/* Mobile & Small Screen Styles */
@media (max-width: 768px) {
  .service-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 100%; /* Ensure no width overflow */
  }
}
