/* QuoteGoogleForm.css */
.form-container {
  background-color: #0206173b; /* White background for the container */
  padding: 20px; /* Add padding around the iframe */
  border-radius: 10px; /* Round corners for the container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for a modern look */
  max-width: 1000px; /* Max width for larger screens */
  margin: 0 auto; /* Center it horizontally */
  text-align: center; /* Center the text and iframe */
  overflow: hidden; /* Prevent anything from overflowing */
}

h2 {
  font-size: 2rem; /* Larger heading */
  color: #bebebe; /* Dark text color */
  margin-bottom: 20px; /* Space between heading and form */
  font-family: 'Arial', sans-serif;
}

iframe {
  border: none; /* Remove default border */
  border-radius: 10px; /* Round iframe corners to match the container */
  width: 100%;
  height: 700px;
  max-width: 100%; /* Ensure iframe is responsive */
}

@media (max-width: 768px) {
  .form-container {
    padding: 10px; /* Less padding on smaller screens */
  }

  iframe {
    height: 600px; /* Adjust iframe height for smaller screens */
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 5px; /* Minimal padding on very small screens */
  }

  iframe {
    height: 500px; /* Adjust iframe height further */
  }

  h2 {
    font-size: 1.5rem; /* Smaller heading on mobile */
  }
}
