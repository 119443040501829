/* HEADER */
.App-header {
  background-color: #020617;
  display: flex;
  justify-content: center; /* Spread out left and right */
  align-items: center; /* Vertically center the content */
  padding: 20px;
  position: relative; /* Allow absolute positioning for nav-toggle */
  height: 70px; /* Set height for the header */
}


a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Make the link color inherit from the h1 */
}

a:hover {
  text-decoration: underline; /* Add underline on hover */
}
     

/* Navigation Button (Hamburger) */
.nav-toggle {
  position: absolute; /* Absolute positioning inside header */
  top: 50%; /* Vertically center the hamburger */
  right: 20px; /* Align hamburger to the right */
  transform: translateY(-50%); /* Adjust for perfect vertical centering */
  background-color: transparent;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  z-index: 101; /* Make sure it’s above other elements */
}

.App-header h1 {
  margin: 0;
  font-size: 2rem;
}






  
  .nav-toggle .fa-bars {
    display: block; /* Show hamburger icon */
  }
  
  .nav-toggle .fa-times {
    display: none; /* Hide close icon initially and permanently */
  }
  
  /* When the nav is open (when the drawer is visible) */
  .nav-drawer.show {
    right: 0;
  }
  
  .nav-toggle.open .fa-bars {
    display: block; /* Ensure hamburger stays visible when nav is open */
  }
  
  .nav-toggle.open .fa-times {
    display: none; /* Do not show close icon */
  }
  
  /* Navigation Drawer */
  .nav-drawer {
    position: fixed;
    top: 0;
    right: -300px; /* Keep drawer off-screen initially */
    width: 300px;
    height: 100vh;
    background-color: #020617;
    transition: right 0.3s ease; /* Transition when opening and closing the drawer */
    z-index: 100; /* Ensure drawer is below toggle */
  }
  
  .nav-drawer.show {
    right: 0; /* Move the drawer into view from the right */
  }
  
  /* Drawer Content (links) */
  .nav-drawer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-drawer li {
    margin: 10px 0;
  }
  
  .nav-drawer a {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    padding: 10px;
  }
  
  /* Close Button Inside Drawer */
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px; /* Align close button to the top-right inside the drawer */
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  