/* Footer.css */

.App-footer {
    
    background-color:hsl(180, 3%, 21%); /* Light gray background */
    padding: 10px;
    text-align: center; /* Center the content horizontally */
    font-size: 0.8rem; /* Adjust font size as needed */
    color:#b9b9b9
  }
  
  .App-footer p {
    background-color:hsl(180, 3%, 21%); /* Light gray background */
    padding: 10px;
    text-align: center; /* Center the content horizontally */
    font-size: 0.8rem; /* Adjust font size as needed */
    color:#b9b9b9

  }
  
  .App-footer a {
    
    color: #009dff; /* Dark gray text color */
    text-decoration: none; /* Remove underline from link */
    padding: 10px; /* Add some padding around the link */
    font-size: 0.7rem; /* Adjust font size as needed */
  }
  
  .App-footer a:hover {
    font-size: .8rem; /* Adjust font size as needed */
    color: #007bff; /* Change link color on hover */
    text-decoration: underline; /* Add underline on hover */
  }
  


.App-footer a.meet-link {
    color: #333; /* Dark gray text color */
    text-decoration: none; /* Remove underline from link */
    padding: 5px; /* Add some padding around the link */
    display: flex; /* Allow us to align icon and text */
    align-items: center; /* Align icon and text vertically */
  }
  
  .App-footer a.meet-link svg {
    margin-right: 5px; /* Add spacing between icon and text */
    width: 1.5rem; /* Adjust icon size as needed */
    height: 1.5rem; /* Adjust icon size as needed */
  }
  
  .App-footer a.meet-link:hover {
    color: #007bff; /* Change link color on hover */
    text-decoration: underline; /* Add underline on hover */
  }